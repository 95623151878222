import { Injectable } from '@angular/core';
import { first, map, catchError } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { Helper } from '../helpers/helper';
import { LoginForm } from '../models/user/public/login-form';
import { UserResponse } from '../models/user/private/user-response';
import { ResponseApi } from '../models/general/response-api';
import { UserService } from './user.service';
import { FirstLoadService } from './first-load.service';
import { LoginResponse } from '../models/user/public/login-response';
import { ErrorApi } from '../models/general/error-api';
import { LocalStorageService } from './local-storage.service';
import { SettingsProviderService } from './settings-provider.service';
import { UserGroup } from '../models/user/private/user-group';
import { UserGroupName } from '../models/user/private/user-group-name';
import { UserGroupId } from '../models/user/private/user-group-id';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    public loggedIn = false;
    public isInitialized = false;
    private oldVal = false;
    public loginSubject = new Subject<boolean>();

    private role: UserGroupName;
    private roleId: number;

    constructor(private userService: UserService,
                private helper: Helper,
                private localStorageService: LocalStorageService,
                private firstLoadService: FirstLoadService,
                private settingsProviderService: SettingsProviderService) {

        this.firstLoadService.addLoader('login');
    }

    public login(username: string, password: string): Observable<LoginResponse> {
        const login: LoginForm = {
            username,
            password
        };
        return this.userService.login(login).pipe(
            map((result: ResponseApi<UserResponse | ErrorApi[]>) => {
                return this.handleSuccessLoginResponse(result);
            }),
            catchError((error: string) => {
                return this.handleErrorLoginResponse(error);
            })
        );
    }

    private handleSuccessLoginResponse(result: ResponseApi<UserResponse | ErrorApi[]>): LoginResponse {

        if(result && result.statusCode === 200) {
            const user = <UserResponse>result.data;
            this.setLoggedAfterLogin(user);

            return {
                status: 'ok',
                message: ['ok']
            };
        } else {
            this.setNotLoggedAfterLogin();

            const errors = <ErrorApi[]>result.data;
            const errorMessage = this.helper.formatError(errors, false);

            return {
                status: 'message',
                message: errorMessage
            };
        }
    }

    private handleErrorLoginResponse(error: string): Observable<LoginResponse> {
        console.log(error);
        const res: LoginResponse = {
            status: 'error',
            message: [ error ]
        };
        return of(res);
    }

    public logout(remove = false) {
        console.log('logout');
        remove && this.localStorageService.removeUser();

        this.setNotLoggedAfterLogin();
    }

    public isAuthenticated(): boolean | Promise<boolean> {
        const userItem = this.localStorageService.getUser();

        if (this.isInitialized === true) {
            this.sendSubject();
            return this.loggedIn;
        } else if (
            userItem !== null &&
            typeof userItem.id !== 'undefined' && typeof userItem.token !== 'undefined'
        ) {
            return new Promise((resolve, reject) => {
                return this.checkUser()
                    .pipe(first())
                    .subscribe(
                        (result: boolean) => {
                            console.log('success auth!');
                            this.setLogged();
                            resolve(this.loggedIn);
                        },
                        (error: string) => {
                            console.log('error auth', error);
                            this.setNotLogged();
                            resolve(this.loggedIn);
                        }
                    );
            });
        } else {
            this.setNotLogged();
            return this.loggedIn;
        }
    }

    private setNotLogged() {
        this.loggedIn = false;
        this.setRole(UserGroupName.GUEST);
        // this.removeUser();
        this.sendSubject();
        this.isInitialized = true;
    }

    private setNotLoggedAfterLogin() {
        this.loggedIn = false;
        this.setRole(UserGroupName.GUEST);
        this.isInitialized = true;
        this.sendSubject();
    }

    private setLogged() {
        this.loggedIn = true;
        this.checkRole();
        this.sendSubject();
        this.isInitialized = true;
    }

    private setLoggedAfterLogin(user: UserResponse) {
        this.localStorageService.setUser(user);
        this.loggedIn = true;
        this.isInitialized = true;
        this.setRole(user.role);
        this.sendSubject();
    }

    private checkUser(): Observable<boolean> {
        return this.userService.check()
            .pipe(
                map((result: ResponseApi<string>) => {
                    if (result && result.statusCode === 200 && result.message === 'ok') {
                        const token = result.data;
                        this.localStorageService.setToken(token);
                        return true;
                    } else {
                        return false;
                    }
                }));
    }

    private sendSubject(): void {
        if(this.oldVal !== this.loggedIn || !this.isInitialized) {
            this.oldVal = this.loggedIn;
            this.loginSubject.next(this.loggedIn);
            this.firstLoadService.event.next('login');
        }
    }

    private setRole(role: string): void {

        if(this.loggedIn && role) {
            this.role = <UserGroupName>role;
            this.roleId = UserGroup.getId(role);
        } else {
            this.role = UserGroupName.GUEST;
            this.roleId = UserGroupId.GUEST;
        }
    }

    private checkRole(): void {
        const userItem = this.localStorageService.getUser();
        if(userItem && userItem.role) {
            this.setRole(userItem.role);
        } else {
            this.role = UserGroupName.GUEST;
            this.roleId = UserGroupId.GUEST;
        }
    }

    public checkPermission(permission: string): boolean {
        const permissionId = UserGroup.getId(permission);
        return this.roleId >= permissionId;
    }

    public checkIfSamePermission(permission: string): boolean {
        const permissionId = UserGroup.getId(permission);
        return this.roleId === permissionId;
    }

    public loginUser(user: UserResponse): void {
        this.setLoggedAfterLogin(user);
    }


    public getUsername(): string {
        return this.localStorageService.getUsername();
    }

    public getUserId(): number {
        return this.localStorageService.getUserId();
    }

    public getToken(): string {
        return this.localStorageService.getToken();
    }

    public getUserGroupNames(): UserGroupName[] {
        return UserGroup.getUserGroupNames(this.role)
    }
}

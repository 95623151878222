import { BaseComponent } from './base.component';
import { Subject } from 'rxjs';
import { Injector } from '@angular/core';

export class AsynchronouslyInitialisedComponent extends BaseComponent {
    loadedState: Subject<string> = new Subject<string>();
    loadedState$ = this.loadedState.asObservable();
    constructor(injector: Injector) {
        super(injector);
    }
    protected componentLoaded(name: string = "") {
        this.loadedState.next(name);
    }
}
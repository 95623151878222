import { Injectable, Type } from '@angular/core';
import { SettingsService } from './settings.service';
import { FirstLoadService } from './first-load.service';
import { RouteInfo } from './../models/routes/route-info';
import { ResponseApiList } from './../models/general/response-api-list';
import { Routes, Route } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoutingProviderService {

    private routes: Array<RouteInfo> = null;

    public isLoaded = false;

    constructor(private settingsService: SettingsService,
                private firstLoadService: FirstLoadService) {

        this.firstLoadService.addLoader('app-routes');
    }

    public load(): Promise<void> {
        environment.debug && console.log('init RoutingProvider');

        return new Promise((resolve, reject) => {
            this.settingsService.getAppRoutes().subscribe(
                (response: ResponseApiList<RouteInfo>) => {
                    if(response && response.statusCode === 200 && response.data) {
                        this.routes = response.data;
                    }
                    this.firstLoadService.event.next('app-routes');
                    this.isLoaded = true;
                    resolve();
                },
                (error: string) => {
                    console.log(error);
                    this.firstLoadService.event.next('app-routes');
                    this.isLoaded = true;
                    resolve();
                }
            );
        });
    }

    public getRoutes(): Array<RouteInfo> | null {
        return this.routes;
    }

    public getRoutesByModule(name: string): Array<RouteInfo> | undefined {
        return this.routes?.filter(elem => elem.module === name);
    }

    public buildRoutes(moduleName: string, components: Record<string, Type<any>>, modules: Record<string, () => Type<any>>, Guard: Array<any>): Routes | null
    {
        let routes: Routes = null;

        const appRoutes = this.getRoutesByModule(moduleName);

        if(appRoutes) {
            routes = [];

            appRoutes.forEach((elem: RouteInfo) => {

                switch(elem.type) {
                    case 'component':

                        if(elem.component && elem.route && elem.route.length) {
                            const component = this.getComponent(components, elem.component);

                            if(component) {
                                elem.route.forEach((path: string) => {
                                    let route: Route = {
                                        path: path,
                                        canActivate: Guard,
                                        component: component
                                    };

                                    if(elem.extra?.pathMatch) {
                                        route.pathMatch = elem.extra!.pathMatch;
                                    }

                                    if(!routes.find(x => x.path === route.path)) {
                                        routes.push(route);
                                    }
                                });
                            }
                        }
                        break;

                    case 'module':

                        if(elem.route && elem.component && elem.route.length) {
                            const module = this.getModule(modules, elem.component);
                            if(module) {
                                elem.route.forEach((path: string) => {
                                    let route: Route = {
                                        path: path,
                                        canActivate: Guard,
                                        loadChildren: module,
                                    };

                                    routes.push(route);
                                });
                            }
                        }
                        break;
                }
            });

            routes.push({
                path: '**',
                redirectTo: ''
            });

        }

        return routes;
    }

    private getComponent(Components: Record<string, Type<any>>, name: string): Type<any> {
        return Components[name];
    }

    private getModule(Modules: Record<string, () => Type<any>>, name: string): () => Type<any> {
        return Modules[name];
    }

    public getModulePath(name: string): string[] | null {
        const route = this.routes.find(elem => elem.type === 'module' && elem.component === name);

        if(route) {
            return route.route;
        }

        return null;
    }
}

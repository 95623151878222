<!--/.Navbar-->
<mat-toolbar color="primary" class="mat-elevation-z6 fix-nav" [hidden]="hide">
    <div class="menu-top-btn">
        <button fxShow="true" fxHide.gt-sm="true" mat-icon-button (click)="toggle()">
            <mat-icon aria-label="Open side bar">menu</mat-icon>
        </button>
    </div>
    <div class="nav-brand">
        <a class="brand-logo" [routerLink]="helperService.getDefaultHome()">
            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTAgMjUwIj4KICAgIDxwYXRoIGZpbGw9IiNERDAwMzEiIGQ9Ik0xMjUgMzBMMzEuOSA2My4ybDE0LjIgMTIzLjFMMTI1IDIzMGw3OC45LTQzLjcgMTQuMi0xMjMuMXoiIC8+CiAgICA8cGF0aCBmaWxsPSIjQzMwMDJGIiBkPSJNMTI1IDMwdjIyLjItLjFWMjMwbDc4LjktNDMuNyAxNC4yLTEyMy4xTDEyNSAzMHoiIC8+CiAgICA8cGF0aCAgZmlsbD0iI0ZGRkZGRiIgZD0iTTEyNSA1Mi4xTDY2LjggMTgyLjZoMjEuN2wxMS43LTI5LjJoNDkuNGwxMS43IDI5LjJIMTgzTDEyNSA1Mi4xem0xNyA4My4zaC0zNGwxNy00MC45IDE3IDQwLjl6IiAvPgogIDwvc3ZnPg=="
                class="logo" />
        </a>
    </div>

    <span class="nav-spacer"></span>

    <!-- Links -->
    <mat-nav-list role="navigation" fxShow="true" fxHide.lt-md="true">
        <a matLine mat-list-item *ngFor="let menuItem of menuItems" [routerLink]="[menuItem.path]" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: menuItem.extra && menuItem.extra.defaultHome }"> {{ menuItem.title }} </a>
    </mat-nav-list>

    <span class="nav-spacer"></span>

    <ng-container *ngIf="checkoutMenu && checkoutMenu.length">
        <button *ngFor="let menuItem of checkoutMenu" [matTooltip]="menuItem.title" [routerLink]="[menuItem.path]" mat-icon-button>
            <mat-icon>{{ menuItem.icon }}</mat-icon>
        </button>
    </ng-container>

    <!-- languages -->
    <span *ngIf="languages">
        <button [matTooltip]="trans('lang.language')" mat-icon-button [matMenuTriggerFor]="appLang">
            <mat-icon>language</mat-icon>
        </button>
        <mat-menu #appLang="matMenu">
            <button *ngFor="let lang of languages" mat-menu-item class="lang-menu" [matTooltip]="trans('lang.label_' + lang)"
                (click)="changeLang(lang)">
                <img [src]="getIcon(lang)" class="lang-img">
                <span> {{ lang.toUpperCase() }} </span>
            </button>
        </mat-menu>
    </span>
    <!-- Account -->
    <button [matTooltip]="trans('nav.user')" mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #appMenu="matMenu">
        <div *ngFor="let menuItem of menuUserItems">
            <a mat-menu-item [routerLink]="[menuItem.path]" (click)="handle(menuItem)">
                <mat-icon> {{ menuItem.icon }} </mat-icon>
                <span> {{ menuItem.title }} </span>
            </a>
        </div>
    </mat-menu>
</mat-toolbar>
<!--/.Navbar -->
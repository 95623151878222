import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { LoginForm } from '../models/user/public/login-form';
import { Helper } from '../helpers/helper';
import { ErrorApi } from '../models/general/error-api';
import { UserResponse } from '../models/user/private/user-response';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(private http: HttpClient, private helper: Helper) {
    }

    login(login: LoginForm) {
        return this.http.post<ResponseApi<UserResponse | ErrorApi[]>>(`${environment.apiUrl}/v1/user/login`, login);
    }

    check(): Observable<ResponseApi<string>> {
        return this.http.post<ResponseApi<string>>(`${environment.apiUrl}/v1/user/check`, {});
    }
}

import { Component, OnInit, Injector, AfterContentInit, OnDestroy } from '@angular/core';
import { AsynchronouslyInitialisedComponent } from '../asynchronously-initialised.component';
import { RouteMenu } from './../../models/routes/route-menu';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: [ './footer.component.scss' ]
})
export class FooterComponent extends AsynchronouslyInitialisedComponent implements OnInit, OnDestroy, AfterContentInit {

    footerItems: RouteMenu[] = [];
    navSubscription: Subscription;

    constructor(injector: Injector) {

        super(injector);

        this.footerItems = this.appPath.getFooterLinks();

        this.navSubscription = this.appPath.translationsLoaded.subscribe(() => {
            this.footerItems = this.appPath.getFooterLinks();
        });
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {

        if(this.navSubscription) {
            this.navSubscription.unsubscribe();
        }
    }

    ngAfterContentInit(): void {
        this.componentLoaded();
    }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { BothGuard } from './shared/guards/both.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        canActivate: [ BothGuard ],
        component: HomeComponent
    },
    {
        path: 'maintenance',
        canActivate: [ BothGuard ],
        component: MaintenanceComponent
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [ RouterModule.forRoot(routes) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule { }

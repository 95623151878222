import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { ErrorApi } from '../models/general/error-api';
import { environment } from 'src/environments/environment';
import { CreateContactMessage } from '../models/contact/create-contact-message';


@Injectable({
    providedIn: 'root'
})
export class MessageService {
    constructor(private http: HttpClient) {}

    contact(data: CreateContactMessage) {
        return this.http.post<ResponseApi<string | ErrorApi[]>>(`${environment.apiUrl}/message/contact`, data);
    }
}

import { NgModule, Injector, Type } from '@angular/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { BaseComponent } from './shared/components/base.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { TopnavComponent } from './shared/components/topnav/topnav.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { Subscription } from 'rxjs';
import { BothGuard } from './shared/guards/both.guard';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { LoginComponent } from './user/login/login.component';
import { PermissionGuard } from './shared/guards/permission.guard';
import { PageComponent } from './page/page.component';
import { AppRoutingModule } from './app-routing.module';
import { Routes } from '@angular/router';
import { VersionCheckService } from './shared/services/version-check.service';
import { ContentService } from './shared/services/content.service';
import { RegisterComponent } from './user/register/register.component';
import { ContactComponent } from './contact/contact.component';


const AppModuleComponents: Record<string, Type<any>> = {
    'HomeComponent': HomeComponent,
    'MaintenanceComponent': MaintenanceComponent,
    'PageComponent': PageComponent,
    'ServicesComponent': ServicesComponent,
    'LoginComponent': LoginComponent,
    'RegisterComponent': RegisterComponent,
    'ContactComponent': ContactComponent
};

const LazyLoadModules: Record<string, () => any> = {
    'AccountModule' : () => import('./account/account.module').then(m => m.AccountModule),
    'AdminModule' : () => import('./admin/admin.module').then(m => m.AdminModule),
    'CheckoutModule' : () => import('./checkout/checkout.module').then(m => m.CheckoutModule),
};

@NgModule({
    declarations: [
        HomeComponent,
        MaintenanceComponent,
        PageComponent,
        ServicesComponent,
        LoginComponent,
        AppComponent,
        TopnavComponent,
        FooterComponent,
        RegisterComponent,
        ContactComponent
    ],
    imports: [
        SharedModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        MatCarouselModule.forRoot(),
        AppRoutingModule
    ],
    providers: [
        BothGuard,
        PermissionGuard,
        VersionCheckService,
        ContentService
    ],
    bootstrap: [ AppComponent ],
    entryComponents: [
    ]
})
export class AppModule extends BaseComponent {

    private firstLoadSubscription: Subscription;
    private appRoutesSubscription: Subscription;


    constructor(injector: Injector,
                private versionCheckService: VersionCheckService) {

        super(injector);

        console.log('init AppModule');

        this.appRoutesSubscription = this.firstLoadService.event.subscribe((type: string) => {
            if(type === 'app-routes') {

                this.buildRoutes();

                this.appRoutesSubscription.unsubscribe();
            }
        });

        this.firstLoadSubscription = this.firstLoadService.loaded.subscribe(() => {

            console.log('All Loader components loaded!');

            this.checkOnline();

            this.firstLoadSubscription.unsubscribe();
        });
    }

    private checkOnline(): void {
        const isOnline = navigator.onLine;
        const isLive = this.settingsProviderService.getSettingBool('is_live');
        const settings = this.settingsProviderService.getSettings();
        const routes = this.routingProviderService.getRoutes();

        if(isOnline && (!settings || !isLive || !routes)) {
            this.router.navigate(this.helperService.getDefaultMaintenance());
        } else {
            this.versionCheckService.init();
        }
    }

    private buildRoutes() {
        const routes: Routes = this.routingProviderService.buildRoutes('AppModule', AppModuleComponents, LazyLoadModules, [PermissionGuard]);
        if(routes && routes.length) {
            this.router.resetConfig(routes);
        }
    }
}

<div class="container-fluid">
    <div fxLayout="row" fxFlex.xl="22" fxFlex.lg="30" fxFlex.md="45" fxFlex.sm="65" fxFlex.xs="100" class="card-container">

        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    <h2>{{ 'user.login.head_title' | translate }}</h2>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-alert [global]="false"></app-alert>
                <!-- <div class="social-platform-login">
                    <button *ngIf="enableGoogleLogin" class="btn-social-icon btn-google" (click)="socialSignIn('google')">
                        <div class="fab fa-google"></div> <div class="text"> {{ 'user.login.login_with_google' | translate }} </div>
                    </button>
                    <button *ngIf="enableFacebookLogin" class="btn-social-icon btn-fb" (click)="socialSignIn('facebook')">
                        <div class="fab fa-facebook-square"></div> <div class="text"> {{ 'user.login.login_with_facebook' | translate }} </div>
                    </button>
                </div> -->
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <mat-form-field class="form-group" appearance="outline">
                        <mat-label>{{ 'user.login.username' | translate }}</mat-label>
                        <input matInput type="text" formControlName="username" name="username" autocomplete="username" [errorStateMatcher]="errorState"/>

                        <mat-error *ngIf="check(f.username)">
                            <div *ngIf="f.username.hasError('required')">{{ 'user.login.username_required' | translate }}</div>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="form-group" appearance="outline">
                        <mat-label>{{ 'user.login.password' | translate }}</mat-label>
                        <input matInput type="password" name="password" formControlName="password" autocomplete="password" [errorStateMatcher]="errorState" />

                        <mat-error *ngIf="check(f.password)">
                            <div *ngIf="f.password.errors.required">{{ 'user.login.passwd_required' | translate }}</div>
                        </mat-error>
                    </mat-form-field>

                    <div class="form-group" appearance="outline">
                        <button mat-raised-button [disabled]="loading || !loginForm.valid" color="primary" type="submit">
                            {{ 'user.login.login' | translate }}
                        </button>
                        <img *ngIf="loading" [src]="helper.loadingSrc" />

                        <a mat-button [routerLink]="helperService.getDefaultRegister()" color="primary"> {{ 'user.register.register' | translate }}</a>
                        <a mat-button [routerLink]="helperService.getDefaultHome()">{{ 'general.cancel' | translate }}</a>
                    </div>

                    <div class="extra-links">
                        <a [routerLink]="helperService.getDefaultRecover()"> {{ 'user.login.recover' | translate }}</a>
                        <a [routerLink]="helperService.getDefaultActivationEmail()">{{ 'user.login.activation_email' | translate }}</a>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>

import { UserGroupName } from '../user/private/user-group-name';
import { NavUser } from './nav-user';
import { UserGroup } from '../user/private/user-group';
import { AppPath } from './../../services/app-path.service';
import { RouteMenu } from './../routes/route-menu';

// const OptionKeys = UserGroup.getGroups();

// type OptionKeys = 'guest' | 'user';

// interface IterateNum {
//     [Symbol.iterator](): Iterable<NavUser>;
//     openPanel(group: UserGroupName): void;
//     isLoggedActivePath(): boolean;
// }


export class NavUserObj implements Iterable<any> {

    [key: string]: NavUser | any;

    public keys: UserGroupName[];

    public [Symbol.iterator]() {
        let index = 0;
        return {
            next: () => {
                const key = this.keys[index];
                const value = this[key];
                const done = index >= this.keys.length;
                index++;
                return {
                    value, done
                };
            }
        };
    }

    public activePath: UserGroupName;

    constructor(appPath: AppPath) {

        this.keys = appPath.getUserGroupNames();

        this.activePath = UserGroupName.GUEST;

        this.keys.forEach((group) => {
            const navs: RouteMenu[] = appPath.getLeftMenuLinks(group);
            this[group] = new NavUser(group, navs);
        });
        // console.log(this);
    }

    public openPanel(group: UserGroupName): void {

        this.keys.forEach((item) => {
            this[item].panelOpenState = this[item].type === group ? true: false;
        });
    }

    public openActiveGroupPanel() {

        this.keys.forEach((item) => {
            this[item].panelOpenState = this[item].type === this.activePath ? true: false;
        });
        // console.log(this);
    }

    public isLoggedActivePath(): boolean {

        return this.activePath === UserGroupName.USER ||
            this.activePath === UserGroupName.PARTNER ||
            this.activePath === UserGroupName.ADMIN;
    }

    public updateMenuItems(appPath: AppPath) {
        this.keys.forEach((group) => {
            const navs: RouteMenu[] = appPath.getLeftMenuLinks(group);
            this[group].replaceMenuItems(navs);
        });
        // console.log(this);
    }

}
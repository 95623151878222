<div class="container-fluid">
    <h1 class="title-head">
        {{ 'services.head_title' | translate }}
        <div class="line-full"></div>
    </h1>
    <mat-card class="example-card">
        <p>services works!</p>
    </mat-card>
</div>


import { Injectable } from '@angular/core';
import { SettingsProviderService } from '../services/settings-provider.service';
import { RoutingProviderService } from '../services/routing-provider.service';
import { forkJoin } from 'rxjs';
import { FirstLoadService } from '../services/first-load.service';

@Injectable({
    providedIn: 'root',
})
export class AppInitializerProvider {

    constructor(private settingsProviderService: SettingsProviderService,
                private routingProviderService: RoutingProviderService,
                private firstLoadService: FirstLoadService) {

        this.firstLoadService.addLoader('app-init');
    }

    public load(): Promise<void> {
        return new Promise((resolve, reject) => {

            const settingsReq = this.settingsProviderService.load();
            const routingReq = this.routingProviderService.load();

            forkJoin([ settingsReq, routingReq ])
                .pipe().subscribe(
                    () => {
                        this.firstLoadService.event.next('app-init');
                        resolve();
                    },
                    () => {
                        this.firstLoadService.event.next('app-init');
                        resolve();
                    }
                );
        });
    }
}

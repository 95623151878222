import { FirstLoadService } from 'src/app/shared/services/first-load.service';
import { Injectable } from '@angular/core';
import { RouteInfo } from '../models/routes/route-info';
import { Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
import { LocalStorageService } from './local-storage.service';
import { SettingsProviderService } from './settings-provider.service';
import { RoutingProviderService } from './routing-provider.service';
import { RouteMenu } from '../models/routes/route-menu';
import { UserGroupName } from '../models/user/private/user-group-name';
import { UserGroup } from '../models/user/private/user-group';
import { Helper } from './../helpers/helper';

@Injectable({
    providedIn: 'root',
})
export class AppPath {

    public lang: string;

    public langChange = new Subject<string>();
    public translationsLoaded = new Subject<boolean>();
    public customSubject = new Subject<string>();

    private routes: RouteInfo[] = [];

    public allowedLangs: string[];
    private defaultLang: string;

    private eventSubscription: Subscription;

    constructor(private settingsProviderService: SettingsProviderService,
                private firstLoadService: FirstLoadService,
                private authenticationService: AuthenticationService,
                private localStorageService: LocalStorageService,
                private helper: Helper,

                private routingProviderService: RoutingProviderService) {

        this.firstLoadService.addLoader('app-path');

        this.allowedLangs = environment.allowedLangs;
        this.defaultLang = environment.defaultLang;
        this.localStorageService.setDefaultLang(this.defaultLang);

        this.eventSubscription = this.firstLoadService.event.subscribe((type: string) => {
            if(type === 'app-init') {

                let defaultLang = this.settingsProviderService.getSettingString('site_language');
                defaultLang = defaultLang ? defaultLang : environment.defaultLang;
                this.defaultLang = defaultLang;
                this.localStorageService.setDefaultLang(defaultLang);

                const languages = this.settingsProviderService.getSettingArray('languages');
                this.allowedLangs = languages ? languages : environment.allowedLangs;

                const lang = this.localStorageService.getLangStorage();
                if(this.allowedLangs.indexOf(lang) > -1) {
                    this.lang = lang;
                } else {
                    this.lang = defaultLang;
                    this.localStorageService.setLangStorage(this.lang);
                }

                this.routes = this.routingProviderService.getRoutes() ?? [];

                this.firstLoadService.event.next('app-path');

                this.eventSubscription.unsubscribe();
            }
        });
    }

    public changeLang(lang: string) {
        console.log('lang change');
        this.lang = lang;
        this.localStorageService.setLangStorage(this.lang);
        this.langChange.next(this.lang);
    }


    public checkRoutePermission(path: string): boolean {
        let routeInfo = this.findRouteByPath(path);

        if(routeInfo) {
            return this.authenticationService.checkPermission(routeInfo.permission);
        }

        return false;
    }

    public checkForbidRoutesLogged(route: string): boolean {
        const routeInfo = this.findRouteByPath(route);
        if(routeInfo && routeInfo.extra) {
            return routeInfo.extra.fordbidLogged ?? false;
        }

        return false;
    }
    private findRouteByExtra(key: string): RouteInfo | undefined {
        return this.routes.find((item: RouteInfo) => item.extra && item.extra[key]);
    }

    private findRouteByLocation(key: string): RouteInfo[] {
        return this.routes.filter((item: RouteInfo) => item.location && item.location.indexOf(key) > -1);
    }

    private _findRouteByPath(path: string, exact: boolean = false): RouteInfo[] {
        return this.routes.filter((item: RouteInfo) => {
            if(item.type === 'component') {
                for (let k in item.path) {
                    if(path === item.path[k]) {
                        return true;
                    } else if(!exact && path.indexOf(item.path[k]) > -1) {
                        return true;
                    }
                }
            }
            return false;
        });
    }

    public findRouteByPath(path: string, exactOnly: boolean = false): RouteInfo | undefined {

        // match exact
        let routes = this._findRouteByPath(path, true);

        // match loose
        if(!exactOnly && ( !routes || (routes && routes.length === 0) )) {
            routes = this._findRouteByPath(path, false);
        }

        if(routes && routes.length > 0) {
            return this.helper.last(routes);
        }
        return undefined;
    }

    public getRoutePathArr(routeInfo: RouteInfo | undefined, defaultPath: string = ''): string[] {
        return [ routeInfo ? this.getPath(routeInfo): defaultPath ];
    }

    public getRoutePath(routeInfo: RouteInfo | undefined, defaultPath: string = ''): string {
        return routeInfo ? this.getPath(routeInfo): defaultPath;
    }

    public getRouteTitle(routeInfo: RouteInfo): string {
        return routeInfo && routeInfo.title && routeInfo.title[this.lang] ? routeInfo.title[this.lang] : '';
    }

    public getPath(routeInfo: RouteInfo | undefined): string {
        return routeInfo && routeInfo.path && routeInfo.path[this.lang] ? routeInfo.path[this.lang] : '';
    }

    private findRouteByLocations(keys: string[]): RouteInfo[] {

        return this.routes.filter((item: RouteInfo) => {
            let isMatch = true;

            keys.forEach((key: string) => {
                isMatch = isMatch && item.location && item.location.indexOf(key) > -1;
            });

            return isMatch;
        });
    }

    public getDefaultUser(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultUser');
    }

    public getDefaultHome(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultHome');
    }

    public getDefaultRegister(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultRegister');
    }

    public getDefaultAdminMessages(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultAdminMessages');
    }

    public getDefaultAdminNewPage(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultAdminNewPage');
    }

    public getDefaultAdminPage(id: number): RouteInfo | undefined {
        let route = { ...this.findRouteByExtra('defaultAdminPage') };

        if(route && route.path) {
            for(let i in route.path) {
                route.path[i] = route.path[i].replace('{id}', id.toString());
            }
        }

        return route;
    }

    public getDefaultPage(slug: string | number): RouteInfo | undefined {
        let route = { ...this.findRouteByExtra('defaultPage') };

        if(route && route.path) {
            for(let i in route.path) {
                route.path[i] = route.path[i].replace('{slug}', slug.toString());
            }
        }

        return route;
    }

    public getDefaultAdminPages(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultAdminPages');
    }

    public getDefaultMessages(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultMessages');
    }

    public getDefaultAdminMessage(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultAdminMessage');
    }

    public getDefaultMessage(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultMessage');
    }
    public getDefaultLogin(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultLogin');
    }

    public getDefaultMaintenance(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultMaintenance');
    }
    public getDefaultRecover(): RouteInfo | undefined {
        return this.findRouteByExtra('defaultRecover');
    }
    public getDefaultActivationEmail(): RouteInfo | undefined {
        return this.findRouteByExtra('activationEmail');
    }

    public getTos(): RouteInfo | undefined {
        return this.findRouteByExtra('tos');
    }

    public getPrivacy(): RouteInfo | undefined {
        return this.findRouteByExtra('privacy');
    }

    public getRoutes(): RouteInfo[] {
        return this.routes;
    }

    public getTopMenuLinks(): RouteMenu[] {
        const routes = this.findRouteByLocation('topmenu');

        if(routes && routes.length) {
            return routes.map((route) => this.mapMenu(route));
        }
        return [];
    }

    public getCheckoutMenu(): RouteMenu[] {
        let routes = this.findRouteByLocation('checkoutmenu');

        if(routes && routes.length) {

            routes = routes.filter(route => this.authenticationService.checkPermission(route.permission));

            if(routes && routes.length) {
                return routes.map((route) => this.mapMenu(route));
            }
        }
        return [];
    }

    public getFooterLinks(): RouteMenu[] {
        const routes = this.findRouteByLocation('footer');

        if(routes && routes.length) {
            return routes.map((route) => this.mapMenu(route));
        }
        return [];
    }

    public getUserLinks(): RouteMenu[] {
        const routes = this.findRouteByLocation('user');

        if(routes && routes.length) {
            return routes.map((route) => this.mapMenu(route));
        }
        return [];
    }

    private mapMenu(route: RouteInfo): RouteMenu {
        const menu: RouteMenu = {
            path: route.path[this.lang],
            title: route.title[this.lang],
            icon: route.icon,
            permission: route.permission,
            extra: route.extra
        }
        return menu;
    }

    public getLeftMenuLinks(group: UserGroupName): RouteMenu[] {

        let routes: RouteInfo[] = null;

        switch(group) {
            case UserGroupName.GUEST:
                routes = this.findRouteByLocations(['leftmenu', 'topmenu']);
                break;
            case UserGroupName.USER:
            case UserGroupName.PARTNER:
            case UserGroupName.ADMIN:
                routes = this.findRouteByLocations(['leftmenu', group]);
                break;
        }

        if(routes && routes.length) {
            return routes.map((route) => this.mapMenu(route));
        }

        return [];

    }

    public isGroupPath(group: UserGroupName, path: string): boolean {
        const route = this.findRouteByPath(path);

        return route && route.location.indexOf('leftmenu') > -1 && route.location.indexOf(group) > -1;
    }
    public getGroupByActivePath(path: string): UserGroupName {

        let groupName: UserGroupName = UserGroupName.GUEST;
        let route = this.findRouteByPath(path);

        if(route && route.location && route.location.length) {
            const allGroups = UserGroup.getGroups();

            allGroups.forEach((group) => {
                if(route.location.indexOf(group) > -1) {
                    groupName = group;
                    return;
                }
            });
        }

        return groupName;
    }

    public getUserTopLinks(): RouteMenu[] {
        let routes = this.findRouteByLocation('topuser');

        if(routes && routes.length) {

            routes = routes.filter((route) => this.checkTopUserMenu(route))

            if(routes && routes.length) {
                return routes.map((route) => this.mapMenu(route));
            }
        }
        return [];
    }

    private checkTopUserMenu(menuItem: RouteInfo): boolean {

        const loggedIn = this.authenticationService.loggedIn;

        if(this.authenticationService.checkPermission(menuItem.permission)) {

            if(menuItem.extra && typeof menuItem.extra.logged !== 'undefined') {
                return menuItem.extra.logged === loggedIn;
            }

            return true;
        }

        return false;
    }

    public getUserGroupNames(): UserGroupName[] {
        return this.authenticationService.getUserGroupNames();
    }

    // returnActiveRouteName(currentRoute: string) {
    //     this.routes.forEach(element => {
    //         if(element.route === currentRoute) {
    //             return element.title;
    //         }
    //     });
    // }

    // getTitle(url: string): string {
    //     let title = '';

    //     if(url && url.length > 0) {

    //         let tempPath = url.split(/\?|#/g);
    //         const path = tempPath[0];

    //         const routes = this.routes.filter(item => path.indexOf(item.path) > -1);

    //         if(routes && routes.length > 0) {
    //             const route = this.helper.last(routes);
    //             if(route.title !== '') {
    //                 title = route.title;
    //             }
    //         } else {
    //             const lang = this.allowedLangs.filter(l => l !== this.lang);

    //             if(lang && lang.length > 0) {
    //                 const routes2 = this.routes[ lang[0] ].filter((item: RouteInfo) => path.indexOf(item.path) > -1);

    //                 if(routes2 && routes2.length > 0) {
    //                     const route2 = this.helper.last(routes2);
    //                     if(route2.title !== '') {
    //                         title = route2.title;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     return title;
    // }
}

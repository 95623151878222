<div class="footer-container" id="footer-container">
    <div class="line-full"></div>
    <div class="footer-links">
        <ul class="list-inline">
            <li *ngFor="let item of footerItems" class="list-inline-item">
                <a [routerLink]="[item.path]"> {{item.title}} </a>
            </li>
        </ul>
    </div>
    <footer class="mat-typography">
        <div class="title"> ©{{ 'footer.rights' | translate }} </div>
    </footer>
</div>

<ng-template [ngIf]="loading || error" [ngIfElse]="loaded">
    <div class="loading-shade">
        <app-loading *ngIf="loading"></app-loading>
        <div *ngIf="error" class="error-div">
            <h3> {{ 'general.failed_load' | translate }} </h3>
        </div>
    </div>
</ng-template>
<ng-template #loaded>

    <div class="container-fluid app-home" fxLayout="column wrap">

        <div class="carousel-wrapper">

            <mat-carousel
                [timings]="carouselConf.timings"
                [autoplay]="carouselConf.autoplay"
                [interval]="carouselConf.interval"
                [color]="carouselConf.color"
                [maxWidth]="carouselConf.maxWidth"
                [proportion]="carouselConf.proportion"
                [slides]="carouselConf.slides"
                [loop]="carouselConf.loop"
                [hideArrows]="carouselConf.hideArrows"
                [hideIndicators]="carouselConf.hideIndicators"
                [useKeyboard]="carouselConf.useKeyboard"
                [useMouseWheel]="carouselConf.useMouseWheel"
                [orientation]="carouselConf.orientation" >

                    <mat-carousel-slide
                        #matCarouselSlide
                        *ngFor="let banner of banners; let i = index"
                        [image]="null"
                        [overlayColor]="carouselConf.overlayColor"
                        [hideOverlay]="carouselConf.hideOverlay">
                            <a [href]="banner.link" class="stop-link">
                                <img class="d-block w-100" [src]="banner.img" [alt]="banner.title" />
                            </a>
                    </mat-carousel-slide>
            </mat-carousel>

        </div>

        <div class="home-content" *ngIf="content || loadingContent">
            <mat-card class="example-card">
                <mat-card-content>
                    <div *ngIf="content && !loadingContent" [innerHtml]="content.description"></div>
                    <app-loading *ngIf="loadingContent"></app-loading>
                </mat-card-content>
            </mat-card>
        </div>

    </div>

</ng-template>
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { Helper } from '../helpers/helper';
import { AppPath } from './../services/app-path.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

    constructor(private router: Router,
                private appPath: AppPath,
                private authenticationService: AuthenticationService,
                private helper: Helper) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        const url = state.url;
        // console.log(route, state);
        const isAuth = this.authenticationService.isAuthenticated();

        if (typeof (isAuth) === 'boolean') {
            return this.check(isAuth, url);
        }
        return isAuth.then((auth: boolean) => this.check(auth, url));
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }

    private check(auth: boolean, url: string) {
        this.helper.scrollTop();

        const path = this.helper.getPath(url);

        if(auth === true && this.appPath.checkForbidRoutesLogged(path)) {

            this.router.navigate(this.appPath.getRoutePathArr(this.appPath.getDefaultHome()));
            return false;

        } else if(!this.appPath.checkRoutePermission(path)) {

            this.router.navigate(this.appPath.getRoutePathArr(this.appPath.getDefaultLogin()));
            return false;
        }

        return true;
    }
}

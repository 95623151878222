import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from '../shared/components/base.component';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent extends BaseComponent implements OnInit {

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
    }

}

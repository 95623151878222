// import { SocialRes } from 'src/app/shared/models/social/social-res';
import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormGroupDirective, AbstractControl } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { LoginResponse } from 'src/app/shared/models/user/public/login-response';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MyErrorStateMatcher } from 'src/app/shared/helpers/my-error-state-matcher';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: [ './login.component.scss' ]
})
export class LoginComponent extends BaseComponent implements OnInit {

    @ViewChild(FormGroupDirective) formRef: FormGroupDirective;
    loginForm: FormGroup;
    returnUrl: string;
    // allowRegister = false;
    // enableGoogleLogin = false;
    // enableFacebookLogin = false;

    public errorState: MyErrorStateMatcher;

    constructor(injector: Injector,
                private formBuilder: FormBuilder,
                private alertService: AlertService) {

        super(injector);

        this.loading = false;

        this.errorState = new MyErrorStateMatcher();

        // this.enableGoogleLogin = this.settingsProviderService.getSettingBool( this.isMobile() ? 'google_connect_mobile' : 'google_connect' );
        // this.enableFacebookLogin = this.settingsProviderService.getSettingBool( this.isMobile() ? 'facebook_connect_mobile' : 'facebook_connect' );
    }

    ngOnInit() {

        this.loginForm = this.formBuilder.group({
            username: [ '', Validators.required ],
            password: [ '', Validators.required ]
        });

        this.authenticationService.logout(true);

        this.returnUrl =
            this.route.snapshot.queryParams.returnUrl || this.appPath.getRoutePath(this.appPath.getDefaultUser());
    }

    get f() {
        return this.loginForm.controls;
    }

    check(control: AbstractControl) {
        return (this.errorState.submitted || control.touched) && control.errors;
    }

    onSubmit() {
        this.errorState.submitted = true;

        this.alertService.reset();

        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;

        this.authenticationService
            .login(this.f.username.value, this.f.password.value)
            .subscribe(
                (result: LoginResponse) => {
                    if (result.status === 'ok') {
                        this.successLogin();
                    } else {
                        this.failedLogin();
                        if(result.status === 'message') {
                            this.alertService.error(result.message, false, false);
                        }
                    }
                }
            );
    }

    // socialSignIn(type: string) {
    //     this.alertService.reset();

        // this.authenticationService.socialSignIn(type, true).then((result: SocialRes) => {
        //     if(result.status === 'ok') {
        //         this.successLogin();
        //     } else {
        //         this.failedLogin();
        //         if(result.status === 'message') {
        //             this.alertService.error(result.data, false, false);
        //         }
        //     }
        // });
    // }

    private successLogin() {

        this.loading = false;
        this.alertService.success([ this.trans('user.login.success') ], false, true);

        const url = this.helper.getPath(this.returnUrl);
        const queryParams = this.helper.getQueryParams(this.returnUrl);
        this.router.navigate([ url ], { queryParams: queryParams });
    }

    private failedLogin() {
        this.errorState.submitted = false;
        this.formRef.reset();
        this.loading = false;
    }
}

import { AppPath } from './../../../services/app-path.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert.service';
import { AlertModel } from 'src/app/shared/models/general/alert-model';

@Component({
    selector: 'app-alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['alert.component.scss']
})

export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    public message: AlertModel;
    private timeout: number = null;

    public dismissible: boolean = true;

    @Input() global: boolean;

    constructor(private alertService: AlertService, private appPath: AppPath) {
        if(typeof this.global === 'undefined') {
            this.global = false;
        }
    }

    ngOnInit() {

        this.subscription = this.alertService.getMessage().subscribe((message: AlertModel) => {

            if(typeof message !== 'undefined' && message.global === this.global) {
                if(message.type === 'reset') {
                    this.message = undefined;
                    this.triggerChange();
                } else {
                    this.message = message;

                    clearTimeout(this.timeout);
                    if(message.timeout) {
                        const handler: TimerHandler = () => {
                            this.message = undefined;
                            this.triggerChange();
                        }
                        this.timeout = setTimeout(handler, 10000);
                    }

                    this.triggerChange();
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    close() {
        this.message = undefined;
        this.triggerChange();
    }

    private triggerChange() {
        if(this.global) {
            setTimeout(() => {
                this.appPath.customSubject.next('alert-global');
            }, 250);
        }
    }

    alertType(type: string): { [klass: string]: boolean } {

        return {
            'alert': true,
            'alert-dismissible': this.dismissible,
            'alert-global': this.global,
            'alert-success': type === 'success',
            'alert-error': type === 'error',
            'alert-warning': type === 'warning',
            'alert-info': type === 'info',
        };
    }
}

import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { isArray } from 'util';
import { ErrorApi } from '../models/general/error-api';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, AbstractControl, FormArray } from '@angular/forms';
import { Language } from './../models/language/language';
import { FormControls } from '../models/form/form-controls';

@Injectable({
    providedIn: 'root',
})
export class Helper {

    public loadingSrc =
    `data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==`;

    constructor(private translateService: TranslateService) {
    }

    public trans(key: string): string {
        return this.translateService.instant(key);
    }

    public formatError(errors: ErrorApi[], field: boolean = false): string[] {
        let errorMessage: string[];

        if(errors) {
            errorMessage = [];

            errors.forEach((err: ErrorApi) => {
                if (err.field === 'exception') {
                    console.log(err.message);
                } else {
                    if(field) {
                        const msg = err.message?.map(x => err.field + ': ' + x);
                        errorMessage.push(...msg);
                    } else {
                        errorMessage.push(...err.message);
                    }
                }
            });

        }
        return errorMessage;
    }

    public setApiErrorsToForm(errors: ErrorApi[], f: FormControls) {
        if(errors && errors.length && f) {
            errors.forEach((err: ErrorApi) => {
                if(err.field === 'exception') {
                    console.log(err.message);
                } else if(typeof f[ err.field ] !== 'undefined') {
                    f[ err.field ].setErrors({ custom: true });

                    const msg = err.message.reduce((prev, current) => prev = prev + ' <br> ' + current);

                    Object.defineProperty(f[ err.field ], 'msg', {
                        value: msg,
                        writable: true
                    });
                }
            });
        }
    }

    public setApiErrorsToMultiForm(errors: ErrorApi[], f: Array<FormGroup>, prefix: string = '', matchField: string = '') {

        if(errors && errors.length && f && f.length) {

            errors.forEach((err: ErrorApi) => {
                if(err.field && err.field.length && err.field !== 'exception') {

                    if(err.field.indexOf('.') > 0) {

                        // descriptions.1.slug
                        const fields = err.field.replace(prefix + '.', '').split('.');
                        const matchFieldVal = fields[0];
                        const field = fields[1];

                        if(field != matchField) {

                            const fg: FormGroup | undefined = f.find((value: FormGroup) => value.controls?.[matchField]?.value == matchFieldVal);
                            if(fg) {
                                const control = fg.controls[field];
                                if(control) {
                                    control.setErrors({ custom: true });
                                    const msg = err.message.reduce((prev, current) => prev = prev + ' <br> ' + current);

                                    Object.defineProperty(control, 'msg', {
                                        value: msg,
                                        writable: true
                                    });
                                }
                            }
                        }
                    }

                }
            });
        }
    }

    public scrollTop() {
        setTimeout(() => {
            const element = document.querySelector('html');
            if(element && element.scrollTop) {
                element.scrollTop = 0;
            }
        }, 300);
    }

    public last(array: Array<any>): any {
        return array[ array.length - 1 ];
    }

    public cutText(text: string, max: number = 200, displayHellip: boolean = true): string {
        return (
            text.substr(0, max - 1) + (text.length > max ? (displayHellip ? '&hellip;' : '...') : '')
        );
    }

    public getBaseUrl(): string {

        const path = window.location.origin ?
            window.location.origin :
            window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;

        if(path && path.length > 0) {
            return path;
        }

        return '';
    }

    public checkAdsBlocked(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const testURL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'

            const myInit: RequestInit = {
                method: 'HEAD',
                mode: 'no-cors'
            };

            const myRequest = new Request(testURL, myInit);

            fetch(myRequest).then((response) => {
                return response;
            }).then((response) => {
                // console.log(response);
                resolve(false);
            }).catch((e) => {
                // console.log(e);
                resolve(true);
            });
        });
    }

    public getPath(url: string): string {
        if(url) {
            let path: string = decodeURIComponent(url);
            if(url.indexOf('?') > -1) {
                path = url.substring(0, url.indexOf('?'));
            }
            return path;
        }

        return '';
    }

    public getQueryParams(url: string): Record<string, string> | undefined {
        let params: Record<string, string>;
        if(url.indexOf('?') > -1) {
            params = {};
            const querystring = url.substring(url.indexOf('?') + 1).split('&');
            // march and parse
            for(let i = querystring.length - 1; i >= 0; i--) {
                const pair = querystring[ i ].split('=');
                params[ decodeURIComponent(pair[ 0 ]) ] = decodeURIComponent(pair[ 1 ] || '');
            }
        }
        return params;
    }

    public closestInteger(a: number, b: number): number {
        const c1 = a - (a % b);
        const c2 = (a + b) - (a % b);
        if(a - c1 > c2 - a) {
            return c2;
        } else {
            return c1;
        }
    }

    public generatePageSizeOptions(total: number, pageSizeOptions: number[], pageSize: number): number[] {

        if(total < this.last(pageSizeOptions)) {
            pageSizeOptions = pageSizeOptions.filter(x => x <= total);
        }

        return pageSizeOptions;
    }

    public getErrorCount(container: FormGroup): number {
        let errorCount = 0;
        for (let controlKey in container.controls) {
            if (container.controls.hasOwnProperty(controlKey)) {
                if (container.controls[controlKey].errors) {
                    errorCount += Object.keys(container.controls[controlKey].errors).length;
                }
            }
        }
        return errorCount;
    }

    public mapLanguages(langsArr: Array<Language>): Record<number | string, number | string> {

        let langsMap: Record<number | string, number | string> = {};

        if(langsArr && langsArr.length) {

            langsArr.forEach((language: Language) => {
                langsMap[language.code] = language.language_id;
                langsMap[language.language_id] = language.code;
            });
        }

        return langsMap;
    }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserResponse } from '../models/user/private/user-response';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    private defaultLang: string;

    constructor() {
        this.defaultLang = environment.defaultLang;
    }

    public setDefaultLang(lang: string) {
        this.defaultLang = lang;
    }

    public setLangStorage(lang: string) {
        localStorage.setItem(environment.localStorage.lang, lang);
    }

    public removeLangStorage(): void {
        localStorage.removeItem(environment.localStorage.lang);
    }

    public getLangStorage(): string {
        const item = localStorage.getItem(environment.localStorage.lang);
        if(item && item.length > 0) {
            return item;
        } else {
            this.setLangStorage(this.defaultLang);
            return this.defaultLang;
        }
    }

    public setUser(user: UserResponse): void {
        localStorage.setItem(environment.localStorage.currentUser, JSON.stringify(user));
    }

    public removeUser(): void {
        localStorage.removeItem(environment.localStorage.currentUser);
    }

    public getUser(): UserResponse | null {
        const userJson = localStorage.getItem(environment.localStorage.currentUser);
        let userItem = null;
        try {
            userItem = JSON.parse(userJson);
        } catch(error) { }

        return userItem;
    }

    public getToken(): string {
        const user = this.getUser();
        if(user && user.token) {
            return user.token;
        }
        return '';
    }

    public setToken(token: string): void {
        const user = this.getUser();
        if(user) {
            user.token = token;
            this.setUser(user);
        }
    }

    public getUsername(): string {
        const user = this.getUser();
        if(user && user.username) {
            return user.username;
        }
        return '';
    }

    public getUserId(): number {
        const user = this.getUser();
        if(user && user.id) {
            return user.id;
        }
        return 0;
    }

    public getTranslationVersionStorage(lang: string): string {
        const key = environment.localStorage.translationsVersion + lang;
        const version = localStorage.getItem(key);
        if(version) {
            return version;
        }
        return '';
    }

    public setTranslationVersionStorage(data: string, lang: string): void {
        const key = environment.localStorage.translationsVersion + lang;
        localStorage.setItem(key, data);
    }

    public getTranslationsStorage(lang: string) {
        const key = environment.localStorage.translations + lang;
        const json = localStorage.getItem(key);
        let item = null;
        try {
            item = JSON.parse(json);
        } catch(error) { }
        return item;
    }

    public setTranslationsStorage(lang: string, data: any): void {
        const key = environment.localStorage.translations + lang;
        localStorage.setItem(key, JSON.stringify(data));
    }

    public removeTranslationsStorage(lang: string): void {
        const key = environment.localStorage.translations + lang;
        localStorage.removeItem(key);
    }
}

import { Injector, Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from '../services/helper.service';
import { Helper } from '../helpers/helper';
import { TranslateService } from '@ngx-translate/core';
import { AppPath } from '../services/app-path.service';
import { SettingsProviderService } from '../services/settings-provider.service';
import { FirstLoadService } from '../services/first-load.service';
import { RoutingProviderService } from '../services/routing-provider.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class BaseComponent implements OnInit, OnDestroy {

    // service injections
    public helperService: HelperService;
    public helper: Helper;
    protected router: Router;
    protected route: ActivatedRoute;
    protected translateService: TranslateService;
    public appPath: AppPath;
    protected settingsProviderService: SettingsProviderService;
    protected firstLoadService: FirstLoadService;
    protected routingProviderService: RoutingProviderService;
    protected authenticationService: AuthenticationService;

    // base vars
    public loading = true;
    public error = false;

    constructor(private injector: Injector) {

        this.helperService = this.injector.get(HelperService);
        this.helper = this.injector.get(Helper);
        this.router = this.injector.get(Router);
        this.route = this.injector.get(ActivatedRoute);
        this.translateService = this.injector.get(TranslateService);
        this.settingsProviderService = this.injector.get(SettingsProviderService);
        this.appPath = this.injector.get(AppPath);
        this.firstLoadService = this.injector.get(FirstLoadService);
        this.routingProviderService = this.injector.get(RoutingProviderService);
        this.authenticationService = this.injector.get(AuthenticationService);
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    public trans(key: string): string {
        return this.translateService.instant(key);
    }
}
import { AbstractControl } from '@angular/forms';

export class ValidationService {
    static emailValidator(control: AbstractControl) {
        if (typeof control.value !== 'undefined' && control.value !== null) {
            // RFC 2822 compliant regex
            if (
                control.value.match(
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
                )
            ) {
                return null;
            } else {
                return { email: true };
            }
        }
        return null;
    }

    static emailValidator2(control: AbstractControl) {
        if (typeof control.value !== 'undefined' && control.value !== null && control.value.length > 0) {
            // RFC 2822 compliant regex
            if (
                control.value.match(
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
                )
            ) {
                return null;
            } else {
                return { email: true };
            }
        }
        return null;
    }

    static passwordValidator(control: AbstractControl) {
        if (typeof control.value !== 'undefined' && control.value !== null) {

            const password = control.value;

            if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])(?=.*[\W]).*$/.test(password)) {
                return { password: true };
            }

            // {6,100}           - Assert password is between 6 and 100 characters
            // (?=.*[0-9])       - Assert a string has at least one number
            // if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*.,\[\]\(\)]{6,100}$/)) {
            //     return null;
            // } else {
            //     return { password: true };
            // }
        }
        return null;
    }

    static isTrue(control: AbstractControl) {
        if (control.value === true) {
            return null;
        } else {
            return { invalidTrue: true };
        }
    }

    static customValidator(control: AbstractControl) {
        return { custom: false };
    }

    static numbergt0(control: AbstractControl) {

        if (!control.value || !Number.isInteger(control.value) || !(control.value > 0)) {
            return { number: true };
        }
        return null;
    }

    static number(control: AbstractControl) {

        if (!Number.isInteger(control.value)) {
            return { number: true };
        }
        return null;
    }

    static usernameValidator(control: AbstractControl) {
        if (typeof control.value !== 'undefined' && control.value !== null) {

            if (control.value.match(/^([a-zA-Z0-9\.\_\-\@]+)$/)) {
                return null;
            } else {
                return { username: true };
            }
        }
        return null;
    }

    static slugValidator(control: AbstractControl) {
        if (typeof control.value !== 'undefined' && control.value !== null) {

            if (control.value.match(/^([a-zA-Z0-9\_\-]+)$/)) {
                return null;
            } else {
                return { slug: true };
            }
        }
        return null;
    }
}

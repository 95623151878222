import { Component, OnDestroy, OnInit, Input, Injector } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Event } from '@angular/router';
import { BaseComponent } from '../base.component';
import { Subscription } from 'rxjs';
import { RouteMenu } from './../../models/routes/route-menu';

@Component({
    selector: 'app-topnav',
    templateUrl: './topnav.component.html',
    styleUrls: [ './topnav.component.scss' ]
})
export class TopnavComponent extends BaseComponent implements OnInit, OnDestroy {

    @Input() sidenav: MatSidenav;

    menuItems: RouteMenu[] = [];
    menuUserItems: RouteMenu[] = [];
    checkoutMenu: RouteMenu[] = [];

    public loggedIn: boolean;
    // public isAdmin: boolean;

    // public nrUnredUser = 0;
    // public nrUnredAdmin = 0;

    public hide: boolean;

    public languages: string[];

    private loggedSubscription: Subscription;
    private firstLoadServiceSubscription: Subscription;
    // private messageCheckSubscription: Subscription;
    private urlSubscription: Subscription;
    private langChangeSubscription: Subscription;

    // private messageCheckService: MessageCheckService) {
    constructor(injector: Injector) {

        super(injector);

        this.urlSubscription = this.router.events.subscribe((event: Event) => {

            if(event instanceof NavigationEnd) {
                if(event.url) {
                    const path = event.url;
                    if(path.indexOf('/maintenance') > -1) {
                        this.hide = true;
                    } else {
                        this.hide = false;
                    }
                }
            }
        });
    }

    ngOnInit() {

        this.languages = this.appPath.allowedLangs;

        this.firstLoadServiceSubscription = this.firstLoadService.loaded.subscribe(() => {

            this.languages = this.appPath.allowedLangs;

            this.loadMenus();

            this.loggedSubscription = this.authenticationService.loginSubject.subscribe(() => {
                this.loadMenus();
            });


            this.langChangeSubscription = this.appPath.langChange.subscribe(() => {
                this.loadMenus();
            });

            this.firstLoadServiceSubscription.unsubscribe();
        });

    }

    private loadMenus(): void {
        this.menuItems = this.appPath.getTopMenuLinks();
        this.menuUserItems = this.appPath.getUserTopLinks();
        this.checkoutMenu = this.appPath.getCheckoutMenu();
    }

    ngOnDestroy(): void {
        if(this.loggedSubscription) {
            this.loggedSubscription.unsubscribe();
        }
        // if(this.messageCheckSubscription) {
        //     this.messageCheckSubscription.unsubscribe();
        // }
        if(this.urlSubscription) {
            this.urlSubscription.unsubscribe();
        }
        if(this.firstLoadServiceSubscription) {
            this.firstLoadServiceSubscription.unsubscribe();
        }
        if(this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    toggle() {
        if(this.sidenav) {
            this.sidenav.toggle();
        }
    }

    changeLang(lang: string) {
        this.appPath.changeLang(lang);
    }

    public handle(menuItem: RouteMenu): void {
        if(menuItem.extra) {
            if(menuItem.extra.defaultLogout) {
                this.authenticationService.logout(true);
                this.router.navigate(this.helperService.getDefaultHome());
            } else if(menuItem.extra.defaultLogin) {
                this.authenticationService.logout(true);
                this.router.navigate(this.helperService.getDefaultLogin());
            }
        }
    }

    // public goToMessages(type: UserType): void {
    //     switch(type) {
    //         case 'admin':
    //             this.isAdmin && this.helperService.goToMessages(true);
    //             break;
    //         case 'user':
    //             this.helperService.goToMessages(false);
    //             break;
    //     }
    // }

    public lang(): string {
        return this.appPath.lang;
    }

    public getIcon(lang: string): string {
        return './assets/images/icons/' + lang + '.png';
    }
}


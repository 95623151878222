import { Component, OnInit, AfterViewInit, OnDestroy, Injector, ViewChild, HostListener, ElementRef } from '@angular/core';
import { BaseComponent } from './shared/components/base.component';
import { MatSidenavContent, MatSidenav } from '@angular/material/sidenav';
import { Subscription, Subject } from 'rxjs';
import { RouteMenu } from './shared/models/routes/route-menu';
import { environment } from 'src/environments/environment';
import { AlertService } from './shared/services/alert.service';
import { RouterEvent, NavigationEnd } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { WidthHeight } from './shared/models/general/width-height';
import { SidenavMode } from './shared/models/navbar/sidenav-mode';
import { NavUserObj } from './shared/models/navbar/nav-user-obj';
import { UserGroupName } from './shared/models/user/private/user-group-name';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ]
})
export class AppComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
    @ViewChild('matSidenavContent', { static: true }) matSidenavContent: MatSidenavContent;

    public activeSidenavMode: string;

    windowWidth = 0;
    windowHeigth = 0;

    // public nrUnredUser = 0;
    // public nrUnredAdmin = 0;

    public navUsers: NavUserObj;

    private sizeChangeEvent = new Subject<WidthHeight>();

    private sizeChangeEventSubscription: Subscription;
    private loggedSubscription: Subscription;
    private urlSubscription: Subscription;
    private firstLoadSubscription: Subscription;
    // private messageCheckSubscription: Subscription;
    private langChangeSubscription: Subscription;

    constructor(injector: Injector,
                // private messageCheckService: MessageCheckService,
                private elementRef: ElementRef,
                private alertService: AlertService) {

        super(injector);

        this.activeSidenavMode = SidenavMode.default;

        this.firstLoadSubscription = this.firstLoadService.loaded.subscribe(() => {
            this.loading = false;
            this.buildMenu('first');

            this.loggedSubscription = this.authenticationService.loginSubject.subscribe(() => {
                this.buildMenu('logged');
            });

            this.langChangeSubscription = this.appPath.langChange.subscribe(() => {
                this.buildMenu('lang');
            });
            this.firstLoadSubscription.unsubscribe();
        });
    }

    ngOnDestroy(): void {
        if(this.firstLoadSubscription) {
            this.firstLoadSubscription.unsubscribe();
        }
        if(this.loggedSubscription) {
            this.loggedSubscription.unsubscribe();
        }
        if(this.urlSubscription) {
            this.urlSubscription.unsubscribe();
        }
        // if(this.messageCheckSubscription) {
        //     this.messageCheckSubscription.unsubscribe();
        // }
        if(this.sizeChangeEventSubscription) {
            this.sizeChangeEventSubscription.unsubscribe();
        }
        if(this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        this.windowWidth = window.innerWidth;
        this.windowHeigth = window.innerHeight;

        this.urlSubscription = this.router.events.subscribe((event: RouterEvent) => {

            if(event instanceof NavigationEnd) {
                if(!this.navUsers) {
                    this.buildMenu('first');
                }

                if(this.authenticationService.loggedIn && event.urlAfterRedirects) {
                    const path = event.urlAfterRedirects;
                    this.navUsers.activePath = this.appPath.getGroupByActivePath(path);
                } else {
                    this.navUsers.activePath = UserGroupName.GUEST;
                }
                this.setSideNav(this.windowWidth);

                // console.log('route event');
            }
        });
    }

    ngOnInit(): void {
        // this.messageCheckSubscription = this.messageCheckService.newMessage.subscribe((newMessageEvent: NewMessageEvent) => {
        //     this.nrUnredUser = newMessageEvent.nrUnredUser;
        //     this.nrUnredAdmin = newMessageEvent.nrUnredAdmin;
        // });

        this.sizeChangeEventSubscription = this.sizeChangeEvent
            .pipe(debounceTime(250))
            .subscribe((size: WidthHeight) => {

                if(this.windowWidth !== size.width) {
                    this.windowWidth = size.width;
                    this.setSideNav(size.width);
                    // this.appPath.customSubject.next('window-size');
                } else if (!this.loading && this.windowHeigth !== size.height) {
                    this.windowHeigth = size.height;
                    // this.appPath.customSubject.next('window-size');
                }
            });
    }

    public checkExactRouteMatch(routeMenu: RouteMenu) {
        return routeMenu.extra && (
            routeMenu.extra.defaultHome ||
            routeMenu.extra.defaultUser ||
            routeMenu.extra.defaultAdmin
        );
    }

    private buildMenu(type: string) {
        switch(type) {
            case 'init':
            case 'first':
                this.navUsers = new NavUserObj(this.appPath);
                break;
            case 'lang':
                this.navUsers.updateMenuItems(this.appPath);
                break;
            case 'logged':
                this.navUsers = new NavUserObj(this.appPath);
                break;
        }
    }

    setSideNav(width: number) {
        if(this.sidenav) {
            environment.debug && console.log('setSideNav');

            this.navUsers && this.navUsers.openActiveGroupPanel();

            if(width > 960) {
                if(this.navUsers && this.navUsers.isLoggedActivePath()) {
                    this.activeSidenavMode = SidenavMode.user;
                    if(!this.sidenav.opened) {
                        this.sidenav.open();
                    }
                } else {
                    this.activeSidenavMode = SidenavMode.default;
                    if(this.sidenav.opened) {
                        this.sidenav.close();
                    }
                }
            } else {
                this.activeSidenavMode = SidenavMode.default;
                if(this.sidenav.opened) {
                    this.sidenav.close();
                }
            }

            setTimeout(() => { this.checkSidenavMargin() }, 200);
        }
    }

    private handleHref(url: string) {
        const path = this.helper.getPath(url);
        const queryParams = this.helper.getQueryParams(url);
        environment.debug && console.log(url, path, queryParams);
        this.router.navigate([ path ], {
            queryParams: {
                ...queryParams,
                returnUrl:  this.router.url
            }
        });
    }

    private checkSidenavMargin() {

        if(this.matSidenavContent) {
            const nav = this.matSidenavContent.getElementRef().nativeElement;

            if(nav && nav.style) {
                if(this.sidenav.opened) {
                    if(nav.style.cssText === '' || nav.style.cssText.indexOf('margin-left:') === -1) {
                        console.log('fixed left nav margin 1!');
                        setTimeout(() => {
                            nav.style.marginLeft = '300';
                        }, 30);
                    }
                } else {
                    if(nav.style.cssText.indexOf('margin-left:') > -1) {
                        console.log('fixed left nav margin 0!');
                        setTimeout(() => {
                            nav.style.marginLeft = '';
                        }, 30);
                    }
                }
            }
        }
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {

        const size: WidthHeight = {
            width: event.target.innerWidth,
            height: event.target.innerHeight
        };

        this.sizeChangeEvent.next(size);
    }

    @HostListener('document:click', ['$event'])
    clickout(event: any) {

        if(event.target) {
            if(
                event.target.className && (
                    event.target.className === 'stop-link' ||
                    (event.target.className.indexOf && event.target.className.indexOf('stop-link') > -1)
                )
            ) {
                if(event.target.href) {
                    event.preventDefault();
                    const index = event.target.baseURI.length - 1;
                    const url = event.target.href.slice(index);
                    this.handleHref(url);
                }
            } else if(event.target.nodeName === 'IMG') {
                if(event.path) {
                    const aRef = event.path[1];
                    if(aRef.className === 'stop-link' || aRef.className.indexOf('stop-link') > -1) {
                        if(aRef.href) {
                            event.preventDefault();
                            const index = aRef.baseURI.length - 1;
                            const url = aRef.href.slice(index);
                            this.handleHref(url);
                        }
                    }
                }
            }
        }
    }
}

import { BannerOrder } from './../models/banner/banner-order';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpEvent } from '@angular/common/http';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { ResponseApiList } from '../models/general/response-api-list';
import { Banner } from '../models/banner/banner';
import { SortPostModel } from '../models/sort/sort-post-model';
import { map } from 'rxjs/operators';
import { UploadStatus } from '../models/banner/upload-status';
import { ErrorApi } from '../models/general/error-api';
import { BannerActive } from '../models/banner/banner-active';
import { BannerImageFile } from '../models/banner/banner-image-file';

@Injectable({
    providedIn: 'root'
})
export class BannerService {
    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get<ResponseApiList<Banner>>(`${environment.apiUrl}/v1/banner/all`);
    }

    getList(data: SortPostModel) {
        return this.http.post<ResponseApiList<Banner | ErrorApi[]>>(`${environment.apiUrl}/v1/banner/list`, data);
    }

    getActive() {
        return this.http.get<ResponseApiList<BannerActive>>(`${environment.apiUrl}/v1/banner/active`);
    }

    getById(id: number) {
        return this.http.get<ResponseApi<Banner | ErrorApi[]>>(`${environment.apiUrl}/v1/banner/` + id);
    }

    create(banner: Banner) {
        return this.http.post<ResponseApi<Banner | ErrorApi[]>>(`${environment.apiUrl}/v1/banner`, banner);
    }

    update(banner: Banner) {
        return this.http.put<ResponseApi<Banner | ErrorApi[]>>(`${environment.apiUrl}/v1/banner/` + banner.id, banner);
    }

    delete(id: number) {
        return this.http.delete<ResponseApi<boolean | ErrorApi[]>>(`${environment.apiUrl}/v1/banner/` + id);
    }

    upload(img: File) {

        const formFile = new FormData();
        formFile.append('imageFile', img);
        // console.log(formFile);
        return this.http.post<UploadStatus>(`${environment.apiUrl}/v1/banner/upload`, formFile, {
            reportProgress: true,
            observe: 'events',
            responseType: 'json'
        }).pipe(map((event: HttpEvent<UploadStatus>) => {
            switch(event.type) {
                case HttpEventType.UploadProgress:
                    const progress = Math.round(100 * event.loaded / event.total);
                    return{ status: 'progress', message: progress };
                case HttpEventType.Response:
                    return { status: 'response', message: <ResponseApi<BannerImageFile | ErrorApi[]>><unknown>event.body };
                default:
                    // return `Unhandled event: ${event.type}`;
                    return { status: 'other', message: event }
            }
        }));
    }

    updateOrder(banners: BannerOrder[]) {
        return this.http.post<ResponseApi<string | ErrorApi[]>>(`${environment.apiUrl}/v1/banner/update-order`, banners);
    }

}

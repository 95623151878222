import { Injectable } from '@angular/core';
import { ResponseApi } from '../models/general/response-api';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SiteSettings } from '../models/settings/site-settings';
import { Setting } from '../models/settings/setting';
import { ResponseApiList } from './../models/general/response-api-list';
import { RouteInfo } from '../models/routes/route-info';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<ResponseApiList<Setting>>(`${environment.apiUrl}/v1/setting/all`);
    }

    getList() {
        return this.http.get<ResponseApiList<Setting>>(`${environment.apiUrl}/v1/setting/list`);
    }

    getSiteSettings() {
        return this.http.get<ResponseApi<SiteSettings>>(`${environment.apiUrl}/v1/setting/site`);
    }

    getAppRoutes() {
        return this.http.get<ResponseApiList<RouteInfo>>(`${environment.apiUrl}/v1/setting/app-routes`);
    }
}

import { Injectable } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AppPath } from './app-path.service';

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    constructor(private appPath: AppPath,
                private domSanitizer: DomSanitizer,
                private meta: Meta,
                private titleService: Title) {}

    public getDefaultHome(): string[] {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultHome(), '/');
    }

    public getDefaultLogin(): string[] {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultLogin());
    }

    public getDefaultUser() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultUser());
    }

    public getDefaultRegister() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultRegister());
    }

    public getDefaultMessages() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultMessages());
    }

    public getDefaultAdminMessages() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultAdminMessages());
    }

    public getDefaultAdminPage(id: number) {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultAdminPage(id));
    }
    public getDefaultAdminNewPage() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultAdminNewPage());
    }

    public getDefaultPage(id: string | number) {
        return this.appPath.getRoutePath(this.appPath.getDefaultPage(id));
    }

    public getDefaultAdminPages() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultAdminPages());
    }

    public getDefaultMaintenance() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultMaintenance(), '/maintenance');
    }

    public getDefaultRecover() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultRecover());
    }
    public getDefaultActivationEmail() {
        return this.appPath.getRoutePathArr(this.appPath.getDefaultActivationEmail());
    }

    public parseHtml(text: string) {
        return this.domSanitizer.bypassSecurityTrustHtml(text);
    }

    public handleMeta(description: string = '', keywords: string = '') {
        // this.meta.removeTag('name="description"');
        // this.meta.removeTag('property="og:description"');
        // this.meta.removeTag('property="og:keywords"');

        if(description != null && keywords != null) {
            this.meta.updateTag({ name: 'description', content: description });
            this.meta.updateTag({ property: 'og:description', content: description });
            this.meta.updateTag({ name: 'keywords', content: keywords });
        }
    }

    public setTitle(name: string) {
        let title = environment.title + ' | ' + name;

        this.titleService.setTitle(title);
        this.meta.updateTag({ property: 'og:title', content: title });
    }

}

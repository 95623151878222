<div class="loading-shade" *ngIf="loading">
    <app-loading></app-loading>
</div>
<app-topnav [sidenav]="sidenav" [hidden]="loading"></app-topnav>

<mat-sidenav-container class="main" id="mat-sidenav-container" [hidden]="loading">
    <mat-sidenav [mode]="activeSidenavMode" opened="false" class="mat-elevation-z3" color="accent" #sidenav>

        <!-- Links -->
        <mat-nav-list role="navigation" class="nav-custom">
            <mat-accordion *ngIf="navUsers" displayMode="flat">
                 <!-- user type navbar -->
                 <ng-container *ngFor="let navUser of navUsers;">
                    <mat-expansion-panel *ngIf="navUser.menuItems && navUser.menuItems.length" [fxHide.gt-sm]="navUser.type === 'guest'"
                        [expanded]="navUser.panelOpenState" hideToggle="false"
                        (opened)="navUser.panelOpenState = true" (closed)="navUser.panelOpenState = false">

                        <mat-expansion-panel-header>
                            <mat-panel-title class="exp-title">
                                <mat-icon class="mat-icon-rtl-mirror"> {{navUser.panelOpenState ? 'expand_more' : 'chevron_right'}} </mat-icon>
                                {{ navUser.title | translate }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-nav-list *ngIf="navUser.menuItems">
                            <a matLine mat-list-item *ngFor="let menuItem of navUser.menuItems" class="side-line" [routerLink]="[menuItem.path]" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: checkExactRouteMatch(menuItem) }">
                                <mat-icon matListIcon>{{menuItem.icon}}</mat-icon>
                                <div>{{ menuItem.title }}</div>
                                <!-- <div *ngIf="menuItem.extra && menuItem.extra.defaultAdminMessages === true && nrUnredAdmin > 0" [matBadge]="nrUnredAdmin"
                                    matBadgeOverlap="false" color="warm" class="msg-badge"> &nbsp; </div> -->
                            </a>
                        </mat-nav-list>

                    </mat-expansion-panel>
                </ng-container>


            </mat-accordion>
        </mat-nav-list>

    </mat-sidenav>

    <mat-sidenav-content id="mat-sidenav-content" #matSidenavContent>
        <app-alert [global]="true" class="stick-top"></app-alert>

        <div class="main-content" id="main-content">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>

    </mat-sidenav-content>

</mat-sidenav-container>
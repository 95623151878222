import { EnviromentBase } from './environment.base';

export const environment = {
    ...EnviromentBase,
    debug: true,
    production: true,
    domain: 'serviciultau.monsterbox.tk',
    apiUrl: '//serviciultau-api.monsterbox.tk',
    versionCheckInterval: 1000*60*1, // 1 minute
};

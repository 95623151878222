export const EnviromentBase = {
    metaTags: [

    ],
    defaultLang: 'ro',
    allowedLangs: [ 'ro', 'en' ],
    title: 'Serviciul Tau',
    versionCheckURL: '/version.json',
    localStorage: {
        currentUser: 'se_current_user',
        lang: 'se_language',
        translationsVersion: 'se_translations_version_',
        translations: 'se_translations_'
    },
    header: {
        lang: 'language'
    }
};

<div class="maintenance">
    <div class="wrap">
        <mat-card class="example-card">
            <mat-card-content>
                <h3 class="text"> Site is under maintenance! </h3>
                <p class="text-center">
                    <a mat-raised-button color="primary" (click)="onClick()">Reload</a>
                </p>
            </mat-card-content>
        </mat-card>
    </div>
</div>


import { isNumber } from 'util';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Helper } from '../helpers/helper';
import { VersionCheck } from '../models/general/version-check';

@Injectable({
    providedIn: 'root'
})
export class VersionCheckService {
    // this will be replaced by actual hash post-build.js
    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

    private defaultFrequency = 1000 * 60 * 60 * 30;
    private versionCheckURL = environment.versionCheckURL;
    private frequency = environment.versionCheckInterval;

    constructor(private http: HttpClient,
                private snackBar: MatSnackBar,
                private helper: Helper) { }

    /**
     * Checks in every set frequency the version of frontend application
     */
    public init() {
        if(environment.production === true) {
            if(!isNumber(this.frequency)) {
                this.frequency = this.defaultFrequency;
            }
            environment.debug && console.log('version check start', 'frequency', this.frequency);

            setInterval(() => { this.checkVersion() }, this.frequency);
            this.checkVersion();
        }
    }

    /**
     * Will do the call and check if the hash has changed or not
     */
    private checkVersion() {
        // timestamp these requests to invalidate caches
        console.log('Checking version!');

        this.http.get(this.versionCheckURL + '?t=' + new Date().getTime())
            .pipe(first())
            .subscribe(
                (response: VersionCheck) => {
                    const hash = response.hash;
                    const hashChanged = this.hasHashChanged(this.currentHash, hash);

                    // If new version, do something
                    if(hashChanged) {
                        // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
                        // for an example: location.reload();
                        console.log('Version changed!');

                        const snackBarRef = this.snackBar.open(
                            this.helper.trans('general.update_text'),
                            this.helper.trans('general.update'), {
                            duration: 0
                        });

                        snackBarRef.afterDismissed().subscribe(() => {
                            location.reload();
                        });

                    }
                    // store the new hash so we wouldn't trigger versionChange again
                    // only necessary in case you did not force refresh
                    this.currentHash = hash;
                },
                (err: any) => {
                    console.error('Could not get version', err);
                }
            );
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash: string, newHash: string): boolean {
        if(!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
            return false;
        }

        return currentHash !== newHash;
    }
}

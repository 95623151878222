import { Component, OnInit, OnDestroy, ViewEncapsulation, Injector } from '@angular/core';
import { ResponseApi } from '../shared/models/general/response-api';
import { ContentService } from '../shared/services/content.service';
import { Subscription, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorApi } from '../shared/models/general/error-api';
import { BaseComponent } from '../shared/components/base.component';
import { ContentResult } from './../shared/models/content/content-result';
import { Location } from '@angular/common';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: [ './page.component.scss' ],
    encapsulation: ViewEncapsulation.None
})
export class PageComponent extends BaseComponent implements OnInit, OnDestroy {

    public content: ContentResult = null;

    public errorTexts: Array<string>;

    private routeSubscription: Subscription;
    private langChangeSubscription: Subscription;

    constructor(injector: Injector,
                private contentService: ContentService,
                private location: Location) {

        super(injector);
    }

    ngOnInit() {
        this.routeSubscription = this.route.url.subscribe(() => {
            this.loadPage(this.route.snapshot.paramMap.get('slug'));
        });

        this.langChangeSubscription = this.appPath.langChange.subscribe(() => {
            if(this.content) {
                this.loadPage(this.content.content_id.toString());
            }
        });
    }

    ngOnDestroy() {
        if(this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
        if(this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    loadPage(slug: string = null) {

        let isNr = false;
        this.loading = true;
        this.error = false;

        let request: Observable<ResponseApi<ContentResult | ErrorApi[]>> = null;

        if(typeof slug === 'undefined' || slug === null) {
            this.router.navigate(this.helperService.getDefaultHome());
            environment.debug && console.log('fail');
        } else if (Number.isInteger(+slug) && +slug > 0) {
            request = this.contentService.getByName(slug);
            isNr = true;
        } else {
            request = this.contentService.getBySlug(slug);
        }

        request.subscribe(
            (response: ResponseApi<ContentResult | ErrorApi[]>) => {
                if(response && response.statusCode === 200) {
                    this.content = <ContentResult>response.data;

                    this.helperService.setTitle(this.content.title);
                    this.helperService.handleMeta(this.content.meta_description, this.content.meta_keywords);

                    if(isNr && this.content.slug) {
                        this.location.replaceState(this.helperService.getDefaultPage(this.content.slug));
                    }

                    this.error = false;
                } else {
                    const errors = <ErrorApi[]>response.data;
                    this.errorTexts = this.helper.formatError(errors, false);
                    this.error = true;
                    console.log('error on content load!', response.data);

                }
                this.loading = false;
            },
            (error: string) => {
                console.log('error on content load!', error);
                this.error = true;
                this.loading = false;
            }
        );
    }
}

import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { BaseComponent } from './../shared/components/base.component';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { ResponseApi } from '../shared/models/general/response-api';
import { ContentDescription } from './../shared/models/content/content-description';
import { ErrorApi } from '../shared/models/general/error-api';
import { ResponseApiList } from '../shared/models/general/response-api-list';
import { ContentService } from '../shared/services/content.service';
import { BannerActive } from '../shared/models/banner/banner-active';
import { MatCarousel } from '@ngmodule/material-carousel';
import { BannerService } from '../shared/services/banner.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {

    public banners: BannerActive[];

    public content: ContentDescription;

    public loadingContent = false;

    public carouselConf: MatCarousel & { 'overlayColor': string, 'hideOverlay': boolean  }= {
        timings: '250ms ease-in',
        autoplay: true,
        interval: 5000,
        color: 'accent',
        maxWidth: 'auto',
        proportion: 25,
        slides: 5,
        loop: true,
        hideArrows: false,
        hideIndicators: false,
        useKeyboard: true,
        useMouseWheel: true,
        orientation: 'ltr',
        overlayColor: '#00000040',
        hideOverlay: false,
        svgIconOverrides: {
            arrowBack: '',
            arrowForward: ''
        }
    }

    private langChangeSubscription: Subscription;

    constructor(injector: Injector,
                private contentService: ContentService,
                private bannerService: BannerService) {

        super(injector);
    }

    ngOnInit(): void {

        const contentReq: Observable<ResponseApi<ContentDescription | ErrorApi[]>> = this.contentService.getByName('home');
        const bannerReq: Observable<ResponseApiList<BannerActive>> = this.bannerService.getActive();

        forkJoin([ contentReq, bannerReq ])
            .pipe()
            .subscribe(
                ([ resContent, resBanner ]) => {

                    if(resContent && resContent.statusCode === 200 && resContent.data) {
                        this.content = <ContentDescription>resContent.data;
                    } else {
                        this.content = undefined;
                    }

                    if(resBanner && resBanner.statusCode === 200 && resBanner.data && resBanner.data.length) {
                        this.banners = resBanner.data;
                        this.carouselConf.slides = this.banners.length;
                    } else {
                        this.banners = undefined;
                    }

                    this.loading = false;
                },
                (error: string) => {
                    console.log(error);
                    this.error = true;
                    this.loading = false;
                }
            );

            this.langChangeSubscription = this.appPath.langChange.subscribe(() => {
                this.loadingContent = true;

                contentReq.subscribe((response: ResponseApi<ContentDescription | ErrorApi[]>) => {
                    if(response && response.statusCode === 200) {
                        this.content = <ContentDescription>response.data;
                    } else {
                        this.content = undefined;
                    }
                    this.loadingContent = false;
                },
                (error: string) => {
                    this.loadingContent = false;
                    this.content = undefined;
                    console.log(error);
                });
            });
    }

    ngOnDestroy() {

        if(this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }
}

import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FirstLoadService {

    public loaded = new Subject<void>();
    public event = new Subject<string>();

    public isLoaded = false;

    private loaders: Record<string, boolean> = {};

    private eventSubscription: Subscription;

    constructor() {

        this.eventSubscription = this.event.subscribe(
            (data: string) => {
                console.log(data + ' loaded!');

                if(typeof this.loaders[data] !== 'undefined') {
                    this.loaders[data] = true;
                }

                if(this.loaders && this.checkLoaders()) {
                    this.isLoaded = true;
                    this.loaded.next();
                    this.eventSubscription.unsubscribe();
                }
            }
        );
    }

    public addLoader(name: string): void {
        environment.debug && console.log('add loader:', name);
        if(typeof this.loaders[ name ] === 'undefined') {
            this.loaders[name] = false;
        }
    }

    private checkLoaders(): boolean {
        const loaders = {...this.loaders};
        const c1 = Object.keys(loaders);
        const c2 = c1.map((key) => loaders[key]);
        const c3 = c2.reduce((res, element) => res && element, true);

        return !!loaders && c3;
    }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppPath } from '../services/app-path.service';
import { LocalStorageService } from '../services/local-storage.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private appPath: AppPath,
                private localStorageService: LocalStorageService,
                private authenticationService: AuthenticationService,
                private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const lang = this.localStorageService.getLangStorage();
        // add authorization header with jwt token if available
        const token = this.localStorageService.getToken();
        const langHeader = environment.header.lang;

        // console.log(request, request.reportProgress);

        let headers: { [name: string]: string } = {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
        };

        if(!request.url.indexOf('banner/upload')) {
            // banner/upload needs to set itself content type
            headers['Content-Type'] = 'application/json';
        }

        if(token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        if(langHeader && lang) {
            headers[langHeader] = lang;
        }

        request = request.clone({
            headers: new HttpHeaders(headers)
        });

        return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
            if (err.status === 401 || err.status === 403) {
                if(request.url.indexOf('/message/check') === -1 &&
                    request.url.indexOf('/user/check') === -1 &&
                    request.url.indexOf('/social/check') === -1 &&
                    request.url.indexOf('/setting/active') === -1) {
                    // auto logout if 401 response returned from api
                    this.authenticationService.logout(false);
                    this.router.navigate(
                        [ this.appPath.getDefaultLogin().path ],
                        { queryParams: { returnUrl: this.router.url }
                    });
                }
            }
            console.log('error from api jwt!');
            console.log(request.urlWithParams);
            console.log(err);

            const error: string = (err && err.error && err.error.message) || err.statusText;
            return throwError(error);
        }));
    }
}

import { NgModule, SkipSelf, ModuleWithProviders, APP_INITIALIZER, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './components/loading/loading.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HelperService } from './services/helper.service';
import { Helper } from './helpers/helper';
import { MyCustomMaterialModule } from './modules/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AlertComponent } from './components/directives/alert/alert.component';
import { YesNoDialogComponent } from './components/directives/yes-no-dialog/yes-no-dialog.component';
import { CustomSnackbarComponent } from './components/directives/custom-snackbar/custom-snackbar.component';
import { CustomMultiSnackbarComponent } from './components/directives/custom-multi-snackbar/custom-multi-snackbar.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { TranslationProviderFactory } from './providers/translation-provider-factory';
import { TranslationService } from './services/translation.service';
import { FirstLoadService } from './services/first-load.service';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { Subscription } from 'rxjs';
import { LocalStorageService } from './services/local-storage.service';
import { AppPath } from './services/app-path.service';
import { SettingsService } from './services/settings.service';
import { AuthenticationService } from './services/authentication.service';
import { AlertService } from './services/alert.service';
import { AppInitializerProvider } from './providers/app-initializer-provider';
import { UserService } from './services/user.service';
import { AppInitializerProviderFactory } from './providers/app-initializer-provider-factory';
import { SettingsProviderService } from './services/settings-provider.service';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NewLinePipe } from './pipes/new-line.pipe';
import { BothGuard } from './guards/both.guard';
import { PermissionGuard } from './guards/permission.guard';
import { TranslationProviderService } from './services/translation-provider.service';
import { MomentModule } from 'ngx-moment';
import { MessageService } from './services/message.service';
import { BannerService } from './services/banner.service';

@NgModule({
    declarations: [
        SafeHtmlPipe,
        NewLinePipe,
        LoadingComponent,
        AlertComponent,
        YesNoDialogComponent,
        CustomSnackbarComponent,
        CustomMultiSnackbarComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MyCustomMaterialModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslationProviderFactory,
                deps: [
                    TranslationProviderService
                ]
            }
        }),
        MomentModule
    ],
    exports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MyCustomMaterialModule,
        SafeHtmlPipe,
        NewLinePipe,
        AlertComponent,
        YesNoDialogComponent,
        LoadingComponent,
        TranslateModule,
        CustomSnackbarComponent,
        CustomMultiSnackbarComponent,
        MomentModule
    ],
    providers: [
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 5000,
                horizontalPosition: 'right',
                verticalPosition: 'bottom',
                panelClass: 'custom-snackbar'
            }
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        BothGuard,
        PermissionGuard
    ],
    entryComponents: [
        YesNoDialogComponent,
        CustomSnackbarComponent,
        CustomMultiSnackbarComponent
    ]
})
export class SharedModule {

    private eventSubscription: Subscription;

    constructor(@Optional() @SkipSelf() parentModule: SharedModule,
                private localStorageService: LocalStorageService,
                private appPath: AppPath,
                private firstLoadService: FirstLoadService,
                private translate: TranslateService) {

        console.log('init SharedModule');

        if(!parentModule) {

            this.eventSubscription = this.firstLoadService.event.subscribe((type: string) => {
                if(type === 'settings') {
                    this.langInit();
                    this.eventSubscription.unsubscribe();
                }
            });
        } else {
            this.langInit();
        }
    }

    private langInit() {
        const lang = this.localStorageService.getLangStorage();
        this.translate.setDefaultLang(lang);
        this.translate.use(lang).subscribe((trans: Object | undefined) => {
            this.appPath.translationsLoaded.next(!!trans);
        });

        this.appPath.langChange.subscribe((lang: string) => {
            console.log('lang change subscribe module');
            this.translate.use(lang).subscribe((trans: Object | undefined) => {
                this.appPath.translationsLoaded.next(!!trans);
            });
        });
    }

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                LocalStorageService,
                SettingsService,
                SettingsProviderService,
                FirstLoadService,
                TranslationProviderService,
                TranslationService,
                TranslateService,
                Helper,
                UserService,
                AuthenticationService,
                AlertService,
                AppPath,
                HelperService,
                MessageService,
                BannerService,
                {
                    provide: APP_INITIALIZER,
                    useFactory: AppInitializerProviderFactory,
                    deps: [ AppInitializerProvider, SettingsProviderService, FirstLoadService, SettingsService ],
                    multi: true
                }
            ]
        };
    }
}

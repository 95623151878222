import { Injectable, Injector } from '@angular/core';
import { SettingsService } from '../services/settings.service';
import { FirstLoadService } from '../services/first-load.service';
import { SiteSettings } from '../models/settings/site-settings';
import { ResponseApi } from '../models/general/response-api';

@Injectable({
  providedIn: 'root'
})
export class SettingsProviderService {

    private settings: SiteSettings = null;

    constructor(private settingsService: SettingsService,
                private firstLoadService: FirstLoadService) {

        this.firstLoadService.addLoader('settings');
    }

    public getSettings(): SiteSettings {
        return this.settings;
    }

    public getSettingNumber(key: string): number {
        if(this.settings && this.settings[key]) {
            return +this.settings[key];
        }
        return 0;
    }

    public getSettingString(key: string): string {
        if(this.settings && this.settings[key]) {
            return this.settings[key];
        }
        return '';
    }

    public getSettingBool(key: string): boolean {
        if(this.settings && this.settings[key]) {
            return this.settings[key];
        }
        return false;
    }

    public getSettingArray(key: string): Array<any> | null {
        if(this.settings && this.settings[key]) {
            return this.settings[key];
        }
        return null;
    }

    public load(): Promise<void> {
        console.log('init load settings');

        return new Promise((resolve, reject) => {
            this.settingsService.getSiteSettings().subscribe(
                (response: ResponseApi<SiteSettings>) => {
                    if(response && response.statusCode === 200 && response.data) {
                        this.settings = response.data;
                    }
                    this.firstLoadService.event.next('settings');
                    resolve();
                },
                (error: string) => {
                    console.log(error);
                    this.firstLoadService.event.next('settings');
                    resolve();
                }
            );
        });
    }
}

import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from './../shared/components/base.component';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent extends BaseComponent implements OnInit {

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        const isLive = this.settingsProviderService.getSettingBool('is_live');
        const settings = this.settingsProviderService.getSettings();
        const isOnline = navigator.onLine;
        const routes = this.routingProviderService.getRoutes();

        if(isOnline && settings && routes && isLive) {
            this.router.navigate(this.helperService.getDefaultHome());
        }
    }

    onClick() {
        location.href = '/';
    }
}
